import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { breakpoints } from '../../styles/breakpoints'

import Wrapper from '../wrapper/wrapper'

const Grid = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Card = styled.div`
  margin-bottom: 16px;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media ${breakpoints.tablet} {
    margin-bottom: 0;
    padding: 24px;
  }

  @media ${breakpoints.laptop} {
    padding: 36px;
  }

  @media ${breakpoints.desktop} {
    padding: 48px;
  }
`

const StyledLink = styled(Link)`
  transition: opacity 0.2s;

  &:hover,
  &:focus {
    opacity: 0.75;
  }
`

const Img = styled.img`
  height: auto;
  max-width: 100%;
  width: auto;

  @media ${breakpoints.desktop} {
    max-width: 500px;
  }

  @media ${breakpoints.wide} {
    max-width: 800px;
  }
`

export default function StoryCollections(props) {
  const stories = props.stories

  return (
    <Wrapper>
      <Grid>
        {stories.map((story, i) => {
          return (
            <Card key={i}>
              <StyledLink to={`/portfolio/${story.node.slug}`}>
                <Img
                  src={`${story.node.coverImage.file.url}?w=1000`}
                  alt={story.node.title} />
              </StyledLink>
            </Card>
          )
        })}
      </Grid>
    </Wrapper>
  )
}

StoryCollections.propTypes = {
  stories: PropTypes.array.isRequired,
}
